import React, { useState, useEffect, useRef, ChangeEvent, FormEvent, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Box,
  CircularProgress,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isAdmin } from "../types";
import { deleteDefaultImages, getDefaultImages, uploadDefaultImage } from "../api/instance-api";
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from "../store/userSlice";
import { instanceSelector } from '../store/instanceSlice';
import { uploadPreviewImageFromDefault } from '../store/editCourseSlice';
import { getMessage } from '../whitelabel-config/WhitelabelProvider';
import Message from './translation/Message';
import { Skeleton } from '@material-ui/lab';

interface DefaultImageGridDialogProps {
  canEdit: boolean;
  setCourseImage: boolean;
}

const DefaultImageGridDialog: React.FC<DefaultImageGridDialogProps> = ({
 canEdit,
 setCourseImage,
}) => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const prevDefaultImagesRef = useRef<string[]>([]);

  const [name, setName] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [imagesLoading, setImagesLoading] = useState<boolean>(true);
  const [deleteResult, setDeleteResult] = useState<boolean>(false);
  
  const user = useSelector(userSelector);
  const instance = useSelector(instanceSelector);

  const dispatch = useDispatch();


  const getImages = useCallback(async () => {
    if (instance?.defaultImages && instance.defaultImages.length > 0) {
      const currentBlobIds = instance.defaultImages.map((img) => img.blobId);
      if (JSON.stringify(prevDefaultImagesRef.current) !== JSON.stringify(currentBlobIds)) {
        try {
          const urls = await Promise.all(
            instance.defaultImages.map(async (img) => {
              const blobId = img.blobId;
              if (!blobId) return "";
              const blobData = await getDefaultImages(blobId);
              setImagesLoading(false);
              return window.URL.createObjectURL(blobData);
            })
          );
          setImageUrls(urls.filter(Boolean));
          prevDefaultImagesRef.current = currentBlobIds;
        } catch (error) {
          console.error("Error fetching images:", error);
          setError("Failed to load images.");
        }
      }
    }
  }, [instance, setImageUrls, setError, prevDefaultImagesRef]);
  

  useEffect(() => {
    if (open) {
      getImages();
    }
  }, [getImages, open, instance?.defaultImages]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
  }

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleUpload = async (e: FormEvent) => {
    e.preventDefault();

    if (!name || !image) {
      setError("Please provide both a name and an image.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const uploadedImages = await uploadDefaultImage(name, image);
      if (uploadedImages) {
        setSuccess(true);
        setName("");
        setImage(null);
        await getImages();
      }
    } catch (err) {
      setError("An error occurred while uploading the image.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (blobId: string) => {
    if (!instance?.id) return;
    setDeleteResult(false);
    try {
      await deleteDefaultImages(blobId);
      await getImages();
      setDeleteResult(true)
    } catch (error) {
      console.error("Failed to delete image:", error);
      setError("An error occurred while deleting the image.");
    }
  };

  const handleSelectImage = (blobId: string) => {
    setSelectedImage(blobId);
  };

  const handleSelectCourseImageFromDefault = async () => {
    const selectedDefaultImage = instance?.defaultImages?.find(x => x.blobId === selectedImage);
    console.log(selectedDefaultImage, selectedImage);
    if (selectedDefaultImage && selectedDefaultImage.blobUrl) {
      console.log("Selected image confirmed:", selectedDefaultImage);
      dispatch(uploadPreviewImageFromDefault(selectedDefaultImage.blobUrl));
    } else {
      setError("No image selected.");
    }
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {setCourseImage ? getMessage('course.courseAdmin.editCoursePage.chooseFromDefaultImages') : getMessage('siteSettings.handleDefaultImages')}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle style={{ marginBottom: 2 }}>
          {setCourseImage ? getMessage('course.courseAdmin.editCoursePage.chooseFromDefaultImages') : getMessage('siteSettings.handleDefaultImages')}
        </DialogTitle>
        <DialogContent>
          {/* Uploader */}
          {user && isAdmin(user) && canEdit && (
            <Box
              component="form"
              onSubmit={handleUpload}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: 400,
                margin: "auto",
                mb: 4,
              }}
            >
              <Typography variant="h6">{getMessage("siteSettings.upploadImage")}</Typography>
              {error && <Typography color="error">{error}</Typography>}
              <TextField
                label={getMessage("siteSettings.imageName")}
                value={name}
                onChange={handleNameChange}
                required
                margin="normal"
              />
              <Button variant="contained" component="label">
                {getMessage("siteSettings.chooseImage")}
                <input
                  type="file"
                  hidden
                  onChange={handleImageChange}
                  accept="image/*"
                  required
                />
              </Button>
              {image && (
                <Typography variant="body2">{getMessage("siteSettings.chosenImage")} {image.name}</Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginTop: "16px" }}
              >
                {loading ? <CircularProgress size={24} /> : getMessage("siteSettings.upload")}
              </Button>
              <Typography variant="subtitle2" component="p" gutterBottom>{getMessage("siteSettings.reloadSiteDefaultImages")}</Typography>

            </Box>
          )}

          {/* Image Grid */}
          <Grid container spacing={2}>
            {instance?.defaultImages?.map((img, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={img.blobId}>
                <Box position="relative">
                  {imagesLoading ? (
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={200}
                      animation="wave"
                      style={{ borderRadius: "8px" }}
                    />
                  ) : (
                    <img
                      src={imageUrls[index]}
                      alt={`Default image ${index}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        objectFit: "cover",
                        border:
                          setCourseImage && selectedImage === img.blobId
                            ? "3px solid #4caf50"
                            : "none",
                      }}
                    />
                  )}

                  {/* Buttons */}
                  {!imagesLoading && setCourseImage && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 8,
                        left: 8,
                        backgroundColor:
                          selectedImage === img.blobId
                            ? "#4caf50"
                            : "rgba(0,0,0,0.5)",
                        color: "white",
                      }}
                      onClick={() => handleSelectImage(img.blobId)}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  )}

                  {!imagesLoading && user && isAdmin(user) && canEdit && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        color: "white",
                      }}
                      onClick={() => handleDelete(img.blobId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>

          {!canEdit && (
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSelectCourseImageFromDefault}
                disabled={!selectedImage}
              >
                <Message id={"course.courseAdmin.editCoursePage.confirmDefaultImage"}/>
              </Button>
            </Box>
          )}
          
        </DialogContent>
        {success && (
          <Typography variant={"h6"} color="primary" style={{marginLeft: "auto", marginRight:"inherit", marginBottom: "8px"}}>{getMessage("siteSettings.uploadImageSuccess")}</Typography>
        )}
        {deleteResult && (
          <Typography variant={"h6"} color="primary" style={{marginLeft: "auto", marginRight:"inherit", marginBottom: "8px"}}>{getMessage("siteSettings.removeImageSuccess")}</Typography>
        )}
      </Dialog>
    </Box>
  );
};

export default DefaultImageGridDialog;
