import { instanceHttpClient as httpClient } from './httpClient';
import { IncomingStaticContent, InstanceMetaData } from '../whitelabel-config/WhitelabelProvider';
import { Intro, Instance, UpdateInstance, DefaultImage } from '../types';

export const getStaticContent = async (): Promise<IncomingStaticContent> => {
  const response = await httpClient.get(`instance/StaticContent`);
  return response.data;
};

export const getMetaData = async (): Promise<InstanceMetaData> => {
  const response = await httpClient.get(`instance/metadata`);
  return response.data;
};


export const uploadDefaultImage = async (name: string, image: File): Promise<DefaultImage[]> => {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('image', image);

  const response = await httpClient.post<DefaultImage[]>(
    'instance/defaultImage',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        name,
      },
    }
  );

  return response.data;
};

export const getDefaultImages = async (imageId: string): Promise<Blob> => {
  const response = await httpClient.get<Blob>(`instance/defaultImages/${imageId}`, {
    responseType: 'blob',
  });
  return response.data;
}

export const deleteDefaultImages = async (imageId: string) => {
  await httpClient.delete(`instance/defaultImages/${imageId}`);
}

export const getStaticContentImage = async (imageId: string): Promise<Blob> => {
  const response = await httpClient.get<Blob>(
    `instance/StaticContent/image/${imageId}`,
    {
      responseType: 'blob',
    }
  );
  return response.data;
};

export const getFavicon = async () => {
  const response = await httpClient.get<Blob>(`instance/favicon`,
    {
      responseType: 'blob',
    });
  return response.data;
}

export const addStaticContentImage = async (
  path: string,
  image: File,
  isFavicon: boolean = false
): Promise<IncomingStaticContent> => {
  const formData = new FormData();
  formData.append('image', image);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await httpClient.post(`instance/StaticContent/image?path=${path}&isFavicon=${isFavicon}`, formData, config);
  return response.data;
};

export const addStaticContentMessage = async (path: string, value: string): Promise<IncomingStaticContent> => {
  const messagePath = {
    path,
    value
  }
  const response = await httpClient.post(`instance/StaticContent/messages`, messagePath);
  return response.data
};

export const getIntro = async (): Promise<Intro> => {
  const response = await httpClient.get(`instance/Intro`);
  return response.data;
};

export const addIntro = async (newIntro: Intro): Promise<Intro> => {
  const response = await httpClient.put(`instance/Intro`, newIntro);
  return response.data;
};

export const deleteIntro = async () => {
  await httpClient.delete(`instance/Intro`);
};

export const getInstance = async (): Promise<Instance> => {
  const response = await httpClient.get(`instance`);
  return response.data;
};

export const setInstanceSettings = async (instanceSettings: UpdateInstance): Promise<Instance> => {
  const response = await httpClient.patch(`instance/settings`, instanceSettings);
  return response.data;
};
