import {
  Box,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import EditableTitle from '../../../components/EditableTitle';
import { Course, CourseTypes, Instructor } from '../../../types';
import {
  courseIsLoadingSelector,
  deleteCourse,
  updateCourse,
  uploadPreviewImage,
} from '../../../store/editCourseSlice';
import DeleteButton from '../../../components/DeleteButton';
import { getMessage } from '../../../whitelabel-config/WhitelabelProvider';
import { courseAdminDashboardUrl } from '../../../routes/paths';
import { useHistory } from 'react-router-dom';
import Message from '../../../components/translation/Message';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { courseTypesSelector } from '../../../store/selectors';
import { Autocomplete } from '@material-ui/lab';
import CustomChip from '../../../components/CustomChip';
import EditIcon from '@material-ui/icons/Edit';
import UploadIcon from '@material-ui/icons/Publish';
import ScheduleIcon from '@material-ui/icons/Schedule';
import {
  fetchProviders,
  providersSelector,
} from '../../../store/providersSlice';
import { createInstructor } from '../../../api/course-api';
import HtmlEditor from '../../../components/HtmlEditor';
import SafeHtmlRenderer from '../../../components/SafeHtmlRenderer';
import { getDurationText } from '../../../utils';
import InstructorItem from '../../../components/courses/InstructorItem';
import InstructorAutoComplete from '../../../components/InstructorAutocomplete';
import DefaultImageGridDialog from '../../../components/DefaultImageGridDialog';
import { instanceSelector } from '../../../store/instanceSlice';
import { getDefaultImages } from '../../../api/instance-api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    tags: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    previewImage: {
      maxHeight: 400,
      maxWidth: 600,
    },
    autocompleteInput: {
      '& input': {
        padding: '12px !important',
      },
    },
  })
);

interface EditState {
  formattedIntroText?: string;
}

type EditIntroductionSubpageProps = {
  course: Course;
};
const EditIntroductionSubpage = ({ course }: EditIntroductionSubpageProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editState, setEditState] = useState<EditState>({});
  const [isEditingCourseType, setIsEditingCourseType] =
    useState<boolean>(false);
  const [selectedCourseType, setSelectedCourseType] =
    useState<CourseTypes | null>(null);
  const imageUploadRef = useRef<HTMLInputElement>(null);
  const [defaultImageUrl, setDefaultImageUrl] = useState('');

  const courseTypes = useSelector(courseTypesSelector);
  const providers = useSelector(providersSelector);
  const isLoading = useSelector(courseIsLoadingSelector);
  const instance = useSelector(instanceSelector);
  
  const isDefault = instance?.defaultImages?.find(x => x.blobUrl === course.previewImageUrl);
  
  const getImg = useCallback(async () => {
    const defaultImage = instance?.defaultImages?.find(x => x.blobUrl === course.previewImageUrl);
    if (defaultImage) {
      try {
        if (defaultImage && defaultImage.blobId) {
          const blobData = await getDefaultImages(defaultImage?.blobId);
          setDefaultImageUrl(window.URL.createObjectURL(blobData));
        }

      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }
  }, [instance, course]);
  
  useEffect(() => {
    if (course && courseTypes) {
      const courseType = courseTypes.find(
        (ct) => ct.courseType.id === course.courseType
      );
      setSelectedCourseType(courseType || null);
    }
    if(instance && instance.defaultImages && instance?.defaultImages?.length > 0) {
      getImg();
    }
  }, [course, courseTypes]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const startEditing = () => {
    setIsEditing(true);
  };

  const stopEditing = () => {
    setIsEditing(false);
    if (!editState.formattedIntroText) {
      return;
    }
    dispatch(updateCourse({ ...(course as Course), ...editState }));
  };

  const handleAddInstructor = (instructorToAdd: Instructor) => {
    const courseToUpdate = course as Course;
    dispatch(
      updateCourse({
        ...courseToUpdate,
        instructors: [...courseToUpdate.instructors, instructorToAdd],
      })
    );
  };

  const handleChangeCourseName = (name: string) => {
    dispatch(updateCourse({ ...(course as Course), name }));
  };

  const handleDeleteCourse = () => {
    dispatch(deleteCourse());
    history.push(courseAdminDashboardUrl);
  };

  const handleFormattedIntroChange = (formattedIntroText: string) => {
    setEditState({ formattedIntroText });
  };

  const handleProviderChange = (event: ChangeEvent<{ value: unknown }>) => {
    const providerId = event.target.value as string;
    const provider = providers?.find((p) => p.id === providerId);
    if (provider) {
      dispatch(
        updateCourse({ ...(course as Course), provider, instructors: [] })
      );
    }
  };

  const handleAddTag = (tagToAdd: string) => {
    const courseToUpdate = course as Course;
    dispatch(
      updateCourse({
        ...courseToUpdate,
        tags: [...courseToUpdate.tags, tagToAdd],
      })
    );
  };

  const handleDeleteTag = (tagToDelete: string) => () => {
    const courseToUpdate = course as Course;
    const updatedTags = courseToUpdate.tags.filter((t) => t !== tagToDelete);
    dispatch(
      updateCourse({
        ...courseToUpdate,
        tags: updatedTags,
      })
    );
  };

  const handleCreateNewInstructor = (name: string) => {
    if (course && course.provider) {
      createInstructor(course.provider.id, name, null).then((instructor) => {
        handleAddInstructor(instructor);
        dispatch(fetchProviders());
      });
    }
  };

  const handleRemoveInstructor = (instructorToRemove: Instructor) => {
    const courseToUpdate = course as Course;
    dispatch(
      updateCourse({
        ...courseToUpdate,
        instructors: courseToUpdate.instructors.filter(
          (i) => i.id !== instructorToRemove.id
        ),
      })
    );
  };

  const handleFeaturedChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCourse({ ...(course as Course), featured: event.target.checked })
    );
  };

  const handleActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCourse({ ...(course as Course), isActive: event.target.checked })
    );
  };

  const handleRepeatableChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateCourse({ ...(course as Course), repeatable: event.target.checked })
    );
  };

  const handleChangeCourseType = (courseTypeId: string | null) => {
    if (courseTypeId && course && courseTypeId !== course.courseType) {
      dispatch(
        updateCourse({ ...(course as Course), courseType: courseTypeId })
      );
    } else {
      const courseType = courseTypes?.find(
        (ct) => ct.courseType.id === course?.courseType
      );
      setSelectedCourseType(courseType || null);
    }
    setIsEditingCourseType(false);
  };

  const handlePreviewImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      dispatch(uploadPreviewImage(event.target.files[0]));
    }
  };

  const handleDeletePreviewImage = () => {
    dispatch(updateCourse({ ...(course as Course), previewImageUrl: '' }));
  };

  if (isLoading || !course) {
    return <CircularProgress size={64} />;
  }

  return (
    <Box>
      <Box className={classes.flexCenter} justifyContent="space-between" mb={4}>
        <EditableTitle title={course.name} onChange={handleChangeCourseName} />
        <DeleteButton
          itemCategory={getMessage('course.course')}
          itemName={course.name}
          dialogTitle={getMessage('course.courseAdmin.deleteCourse')}
          onDelete={handleDeleteCourse}
        />
      </Box>

      <Box mb={4}>
        <Typography variant="subtitle2">
          <Message id="course.courseAdmin.editCoursePage.visibility" />
        </Typography>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            control={
              <Switch
                checked={course.isActive}
                onChange={handleActiveChange}
                name="active"
                color="primary"
              />
            }
            label={getMessage('course.courseAdmin.activeCourseType')}
          />
          <FormControlLabel
            control={
              <Switch
                checked={course.featured}
                onChange={handleFeaturedChange}
                name="featured"
                color="primary"
              />
            }
            label={getMessage('course.featured')}
          />
        </Box>
      </Box>

      <Box mb={4}>
        <FormControlLabel
          control={
            <Switch
              checked={course.repeatable}
              onChange={handleRepeatableChange}
              name="active"
              color="primary"
            />
          }
          label={getMessage('course.courseAdmin.editCoursePage.repeatable')}
        />
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.courseAdmin.editCoursePage.introduction" />
        </Typography>
        <Box mt={1} mb={2}>
          {isEditing ? (
            <HtmlEditor
              html={course.formattedIntroText}
              onChange={handleFormattedIntroChange}
            />
          ) : (
            <SafeHtmlRenderer
              html={editState.formattedIntroText || course.formattedIntroText}
            />
          )}
        </Box>
        {isEditing ? (
          <Button variant="contained" color="primary" onClick={stopEditing}>
            <Message id="course.courseAdmin.editCoursePage.done" />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={startEditing}
          >
            <Message id="course.courseAdmin.editCoursePage.edit" />
          </Button>
        )}
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.courseAdmin.editCoursePage.previewImage" />
        </Typography>
        <Box mt={1} mb={2}>
          {course.previewImageUrl && !isDefault && (
            <img
              src={course.previewImageUrl}
              alt="Course preview"
              className={classes.previewImage}
            />
          )}
          {isDefault && (
            <img
              src={defaultImageUrl}
              alt="Course preview"
              className={classes.previewImage}
            />
          )}
        </Box>
        <Box display="flex">
          <Box>
            <Box mr={2}>
              <DefaultImageGridDialog
                canEdit={false}
                setCourseImage={true}
              />
            </Box>
            <Box>
              <input
                ref={imageUploadRef}
                type="file"
                accept="image/*"
                hidden
                onChange={handlePreviewImageUpload}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<UploadIcon />}
            onClick={() =>
              imageUploadRef.current && imageUploadRef.current.click()
            }
          >
            {course.previewImageUrl ? (
              <Message id="course.courseAdmin.editCoursePage.uploadNew" />
            ) : (
              <Message id="course.courseAdmin.editCoursePage.upload" />
            )}
          </Button>
          {course.previewImageUrl && (
            <Box ml={2}>
              <Button variant="contained" onClick={handleDeletePreviewImage}>
                <Message id="course.courseAdmin.editCoursePage.delete" />
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.courseAdmin.editCoursePage.content" />
        </Typography>
        <Box className={classes.flexCenter} mt={1}>
          <ScheduleIcon />
          <Typography>{getDurationText(course.length)}</Typography>
        </Box>
      </Box>

      <Box mb={6}>
        <Box className={classes.flexCenter}>
          <Typography variant="subtitle2">
            <Message id="course.courseAdmin.editCoursePage.courseType" />
          </Typography>
        </Box>
        <Box pt={1} pb={2}>
          {!isEditingCourseType && (
            <Typography>{selectedCourseType?.courseType.name}</Typography>
          )}
          {isEditingCourseType && (
            <Box maxWidth="280px">
              <Autocomplete
                id="courseType-of-this-course"
                options={courseTypes || []}
                getOptionLabel={(option) => option.courseType.name}
                getOptionSelected={(option, value) =>
                  option.courseType.id === value.courseType.id
                }
                value={selectedCourseType}
                onChange={(_e, value, reason) => setSelectedCourseType(value)}
                renderInput={(params) => (
                  <TextField
                    error={false}
                    {...params}
                    classes={{ root: classes.autocompleteInput }}
                    required
                    placeholder={getMessage(
                      'course.courseAdmin.editCoursePage.selectCourseType'
                    )}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <CustomChip
                      {...getTagProps({ index })}
                      label={option.courseType.name}
                    />
                  ));
                }}
                noOptionsText={getMessage('handleActors.noMatchingActors')}
              />
            </Box>
          )}
        </Box>
        {isEditingCourseType ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleChangeCourseType(selectedCourseType?.courseType.id || null)
            }
          >
            <Message id="course.courseAdmin.editCoursePage.done" />
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => setIsEditingCourseType(true)}
          >
            <Message id="course.courseAdmin.editCoursePage.edit" />
          </Button>
        )}
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.provider" />
        </Typography>
        <Select
          id="provider"
          value={course.provider?.id || ''} // This ensures that the select remains controlled even if no provider exists, if there is no provider the value is a empty string
          onChange={handleProviderChange}
        >
          {providers &&
            providers.map((provider) => (
              <MenuItem value={provider.id} key={provider.id}>
                {provider.name}
              </MenuItem>
            ))}
        </Select>
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.instructors" />
        </Typography>
        <Box mt={1}>
          {course.instructors.map((instructor) => (
            <InstructorItem
              instructor={instructor}
              key={instructor.id}
              showName={true}
              onDelete={handleRemoveInstructor}
            />
          ))}
        </Box>
        {course.provider && (
          // The if statement prevents the site to crash when the masterclass doesn't have a provider
          <InstructorAutoComplete
            instructors={course.provider.instructors.filter(
              (i) => !course?.instructors.find((cI) => cI.id === i.id)
            )}
            onChange={handleAddInstructor}
            onAdd={handleCreateNewInstructor}
          />
        )}
      </Box>

      <Box mb={6}>
        <Typography variant="subtitle2">
          <Message id="course.tags" />
        </Typography>
        <AddTagField onAdd={handleAddTag} />
        <Box className={classes.tags}>
          {course.tags.map((tag) => (
            <CustomChip label={tag} key={tag} onDelete={handleDeleteTag(tag)} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

type AddTagFieldProps = {
  onAdd: (tag: string) => void;
};

const AddTagField = ({ onAdd }: AddTagFieldProps) => {
  const classes = useStyles();
  const [tagToAdd, setTagToAdd] = useState<string>('');

  const handleOnAdd = () => {
    if (tagToAdd) {
      onAdd(tagToAdd);
      setTagToAdd('');
    }
  };

  return (
    <Box className={classes.flexCenter} mb={2}>
      <TextField
        label="Add tag"
        size="small"
        value={tagToAdd}
        onChange={(e) => setTagToAdd(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleOnAdd()}
      />
      {tagToAdd && (
        <Button variant="contained" color="primary" onClick={handleOnAdd}>
          <Message id="course.courseAdmin.editCoursePage.add" />
        </Button>
      )}
    </Box>
  );
};

export default EditIntroductionSubpage;
